import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { lighten } from '@mui/material/styles'
import NextLink from 'next/link'
import React from 'react'

import CarMatchSvg from '../CarMatchSvg'

export default function CarmatchButton({ isPageTransitioning }: { isPageTransitioning: boolean }) {
  return (
    <NextLink href="/carmatch" passHref legacyBehavior>
      <Button
        sx={{
          borderColor: '#8B3E4C',
          '&:hover': {
            backgroundColor: lighten('#8B3E4C', 0.8),
            borderColor: '#8B3E4C'
          }
        }}
        component={Link}
        disabled={isPageTransitioning}
        variant="outlined"
      >
        <CarMatchSvg height="24" width="87" />
      </Button>
    </NextLink>
  )
}
